<template>
	<div class="page_content">
		<div class="page_detail">
			<a-divider orientation="left">补贴信息</a-divider>
			<a-descriptions :column="2">
			    <a-descriptions-item label="姓名">
			        {{ detail.username }}
			    </a-descriptions-item>
			    <a-descriptions-item label="部门">
			          {{ detail.department }}
			    </a-descriptions-item>
				<a-descriptions-item label="统计时间">
				   {{ detail.statistical_time }}
				</a-descriptions-item>
				<a-descriptions-item label="出差天数">
				   {{ detail.days }}天
				</a-descriptions-item>
			    <a-descriptions-item label="应得补贴">
			        {{ detail.money }}
			    </a-descriptions-item>
			    <a-descriptions-item label="补贴状态">
			       {{ detail.status==1?'已补贴':'未补贴' }}
			    </a-descriptions-item>
			   <a-descriptions-item label="补贴时间">
			       {{ detail.subsidy_time}}
			   </a-descriptions-item>
			  
			</a-descriptions>
			
			
			<!-- <a-divider orientation="left">适用补贴规则</a-divider> -->

				<a-divider orientation="left">出差补贴标准</a-divider>
					<div class="li_item">
						<div class="arpproval_title">
							<span class="department_appr">区域</span>
							<span class="department_appr">类型</span>
							<span class="department_appr">补贴金额</span>
						</div>
						<div class="arpproval_title arpproval_title1" v-for="(tan,index) in detail.standard" :key='index'>
							<span class="department_appr">{{tan.region}}</span>
								<span class="department_appr">{{tan.type}}</span>
							<span class="department_appr">{{tan.money}}元/天</span>
						</div>
					</div>
					

			<a-divider orientation="left" >行程信息</a-divider>
			<div class="li_item" >
				<div class="arpproval_title">
					<span class="department_appr">开始时间</span>
					<span class="department_appr">出发地</span>
					<span class="department_appr">结束时间</span>
					<span class="department_appr">到达地</span>
				
				</div>
				<div class="arpproval_title arpproval_title1" v-for="(item,index) in detail.place" :key='index'>
					<span class="department_appr">{{item.start_time}}</span>
					<span class="department_appr">{{item.start_address}}</span>
					<span class="department_appr">{{item.end_time}}</span>
					<span class="department_appr"> {{item.end_address}}</span>
					
				</div>
			</div>
			<div class="box_form box_form_btns">
				<a-button type="defalut" @click="$router.back()">返回</a-button>
			</div>
			
			</div>
			</div>
	</div>
</template>

<script>
	import LookImages from "@/components/LookImages";
	import {
		getSubsidyInfo
	} from '@/api/evection.js'
	import Avatar from "@/components/small/Avatar";
	export default {
		components: {
			LookImages,
			Avatar
		},
		data() {
			return {
				detail: {
					standard: [],
					place:[]
				}
			}
		},
		created() {
			this.get_info()
		},
		methods: {
			
		
			get_info() {
				getSubsidyInfo({
					data: {
						id: this.$route.query.id,
					}

				}).then(res => {
					this.detail = res.data.data
				})
			},
		}
	}
</script>

<style lang="less" scoped>
	.time_line {
		max-width: 600px;
	}

	.avatar_ul {
		display: flex;
		flex-wrap: wrap;

		.avatar {
			margin: 16px 10px 0;
			width: 80px;
		}
	}

	.page_detail {
		margin: 0;
		margin-left: 5%;

	}

	.li_item {
		width: 100%;
		.title_item {
			width: 80px;
			text-align: left;
			display: inline-block;
			color: #000;
		}
		margin-bottom: 30px;
	}
	.arpproval_title {
		padding: 15px 0;
		
		text-align: center;
		background: rgba(245, 245, 245, 1);
		font-size: 14px;
		span{
			width:6%;
			display: inline-block;
		}
		.department_appr {
			width: 25%;
		}
	}
	.arpproval_title1 {
		height: auto;
		background: none;
		border: 1px solid rgba(245, 245, 245, 1);
	}
	.go_see {
		color: #1890ff;
	}
</style>
